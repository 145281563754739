import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMonitoringList() {
  // Use toast
  const toast = useToast()

  const refMonitoringListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'trn_code', label: 'Kode Pengajuan', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
    { key: 'trn_name_project', label: 'Nama Proyek', sortable: true },
    { key: 'sales', label: 'Sales', sortable: true },
    { key: 'trn_type', label: 'Jenis Input', sortable: true },
    { key: 'trn_diskon_request', label: 'Diskon Diminta (%)', sortable: true },
    { key: 'trn_date_request', label: 'Tgl Pengajuan', sortable: true },
    { key: 'trn_periode', label: 'Periode Delivery', sortable: true },
    { key: 'trn_premi', label: 'Premi', sortable: true },
    { key: 'trn_mitrausaha', label: 'Kode MU', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
    { key: 'trn_mitrausaha_name', label: 'MU', sortable: true},
    { key: 'trn_program_df', label: 'Program DF', sortable: true},
    { key: 'trn_flag', label: 'Kode Status', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
    // { key: 'trn_flag_name', label: 'Status', sortable: true},
    { key: 'trn_flag_status', label: 'Status', sortable: true},
    { key: 'Actions', sortable: false },

    // { key: 'nik', label: 'Nama Proyek', sortable: true },
    // { key: 'nama', label: 'Sales', sortable: true },
    // { key: 'bu', label: 'Jenis Input', sortable: true },
    // { key: 'divisi', label: 'Diskon Diminta (%)', sortable: true },
    // { key: 'lvel', label: 'Tgl Pengajuan', sortable: true },
    // { key: 'batas_bawah', label: 'Periode Delivery', sortable: true },
    // { key: 'batas_atas', label: 'Premi', sortable: true},
    // { key: 'df', label: 'Mitra Usaha', sortable: true},    
    // { key: 'program', label: 'Program DF', sortable: true},
    // { key: 'status', label: 'Status', sortable: true},
    // { key: 'Actions', sortable: true },
  ]
  const perPage = ref(10)
  const totalMonitoring = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25]
  const searchQuery = ref('')
  const sortBy = ref('trn_date_request')
  const isSortDirDesc = ref(true)
  // const roleFilter = ref(null)
  // const planFilter = ref(null)
  // const statusFilter = ref(null)
  const proyekFilter = ref(null)
  const tglpengajuanFilter = ref(null)
  const salesFilter = ref(null)
  const jenisFilter = ref(null)
  const diskondimintaFilter = ref(null)
  const periodestartFilter = ref(null)
  const periodeendFilter = ref(null)
  const muFilter = ref(null)
  const dfFilter = ref(null)
  const premiFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMonitoringListTable.value ? refMonitoringListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMonitoring.value,
    }
  })

  const refetchData = () => {
    refMonitoringListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, proyekFilter, tglpengajuanFilter, salesFilter, jenisFilter, diskondimintaFilter, periodestartFilter, 
    periodeendFilter, muFilter, dfFilter, premiFilter
  ], () => {
    refetchData()
  })

  const fetchMonitoring = (ctx, callback) => {
    store
      .dispatch('app-monitoring/fetchMonitoring', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        // cs_category: roleFilter.value,
        // cs_status: planFilter.value,
        // cs_bu: localStorage.getItem('bisnis_unit'),
        emp_id: localStorage.getItem('emp_id'),
        trn_name_project: proyekFilter.value,
        trn_date_request: tglpengajuanFilter.value,
        sales: salesFilter.value,
        trn_type: jenisFilter.value,
        trn_diskon_request: diskondimintaFilter.value,
        trn_periode_start: periodestartFilter.value,
        trn_periode_end: periodeendFilter.value,
        trn_mitrausaha: muFilter.value,
        trn_program_df: dfFilter.value,
        trn_premi: premiFilter.value,
        // trn_flag: statusFilter.value

      })
      .then(response => {
        // const { monitoring, total } = response.data

        callback(response.data.data)
        totalMonitoring.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Monitoring Pengajuan Sales List',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveMonitoringRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveMonitoringRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveMonitoringStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchMonitoring,
    tableColumns,
    perPage,
    currentPage,
    totalMonitoring,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMonitoringListTable,

    resolveMonitoringRoleVariant,
    resolveMonitoringRoleIcon,
    resolveMonitoringStatusVariant,
    refetchData,

    // Extra Filters
    proyekFilter,
    tglpengajuanFilter,
    salesFilter,
    jenisFilter,
    diskondimintaFilter,
    periodestartFilter,
    periodeendFilter,
    muFilter,
    dfFilter,
    premiFilter,
  }
}
