<template>

  <div v-if="session > 0">

    <monitoring-list-add-new
      :is-add-new-monitoring-sidebar-active.sync="isAddNewMonitoringSidebarActive"
      @refetch-data="refetchData"
    />
    <!-- :role-options="roleOptions"
      :plan-options="planOptions" -->

    <!-- Filters -->
    <monitoring-list-filters
      :proyek-filter.sync="proyekFilter"
      :tglpengajuan-filter.sync="tglpengajuanFilter"
      :sales-filter.sync="salesFilter"
      :sales-options="salesOptions"
      :jenis-filter.sync="jenisFilter"
      :jenis-options="jenisOptions"
      :diskondiminta-filter.sync="diskondimintaFilter"
      :diskondiminta-options="diskondimintaOptions"
      :periodestart-filter.sync="periodestartFilter"
      :periodeend-filter.sync="periodeendFilter"
      :mu-filter.sync="muFilter"
      :mu-options="muOptions"
      :df-filter.sync="dfFilter"
      :df-options="dfOptions"
      :premi-filter.sync="premiFilter"
      :premi-options="premiOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

              <b-button
                variant="primary" class="mr-1"
                @click="download_header()"
              >
                <span class="text-nowrap">Download Header</span>
              </b-button>
              <b-button
                variant="primary" class="mr-1"
                @click="download_detail()"
              >
                <span class="text-nowrap">Download Details</span>
              </b-button>
              <!-- <b-button
                variant="primary" class="mr-1"
                @click="download_pdf()"
              >
                <span class="text-nowrap">Download PDF</span>
              </b-button> -->
          
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMonitoringListTable"
        class="position-relative"
        :items="fetchMonitoring"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Group Item -->
        <template #cell(monitoring)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveMonitoringRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-group-item-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.sitename }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveMonitoringRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveMonitoringRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveMonitoringStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template> -->
        <template #cell(trn_flag_status)="data">
          <a 
            @click="status_detail(true, data.item.trn_code)">
            <b-badge
              pill
              :variant="`light-${resolveMonitoringStatusVariant(data.item.trn_flag)}`"
              class="text-capitalize"
            >
              {{ data.item.trn_flag_name }}
            </b-badge>
          </a>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- <b-button
            variant="primary"
            :to="{ name: 'apps-monitoring-pengajuan-detail', params: { trn_code: data.item.trn_code } }"
          >
            <feather-icon icon="SearchIcon" />
            <span class="text-nowrap">Details</span>
          </b-button> -->
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              @click="detail_pengajuan(data.item.trn_code)">
              <feather-icon icon="SearchIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
            <b-dropdown-item 
              @click="download_pdf(data.item.trn_code)">
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Download PDF</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <!-- <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-group-item-ctr-edit', params: { id: data.item.cs_id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showMsgBoxTwo(data.item.cs_id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMonitoring"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <!-- modal -->
      <!-- <b-modal
        id="modal-prevent-closing"
        ref="my-modal"
        title="Duplicate"
        ok-title="Submit"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label="Bisnis Unit"
            label-for="bu"
          >
            <v-select
              v-model="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              input-id="bu_id"
              placeholder="Pilih Bisnis Unit"
            />
          </b-form-group>
        </form>
      </b-modal> -->
      <b-modal id="modal-xl" size="xl" title="Approval Atasan Sales"
        ref="status-modal"
      >
        <b-overlay :show="show" rounded="sm">
          <b-card>
            <b-row>
              <b-table-simple
                hover
                caption-top
                responsive
                class="rounded-bottom mb-0"
              >
                <b-thead head-variant="light">
                  <b-tr class="text-center">
                    <b-th>No</b-th>
                    <b-th>Status</b-th>
                    <b-th>Approval</b-th>
                    <b-th>Level</b-th>
                    <b-th>Tanggal</b-th>
                    <b-th>Catatan</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(val,idx) in tbl_detail_status" :key="idx">
                    <b-td class="text-center">{{ idx+1 }}.</b-td>
                    <b-td>{{ val.status }}</b-td>
                    <b-td>{{ val.approval }}</b-td>
                    <b-td class="text-center">{{ val.app_level }}</b-td>
                    <b-td class="text-center">{{ val.tanggal}}</b-td>
                    <b-td class="text-left">{{ val.catatan }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-row>
          </b-card>
        </b-overlay>
        
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="status_detail(false, '')"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <!-- <b-overlay :show="show" rounded="sm">
        <b-card title="Card with overlay" :aria-hidden="show ? 'true' : null">
          <b-card-text>Laborum consequat non elit enim exercitation cillum.</b-card-text>
          <b-card-text>Click the button to toggle the overlay:</b-card-text>
          <b-button :disabled="show" variant="primary" @click="show = true">
            Show overlay
          </b-button>
        </b-card>
      </b-overlay> -->

      <template #code>
        {{ codeMessageBox }}
      </template>
    </b-card>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination, VBModal, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
// buat ambil api
import axios from '@axios'
// buat nampil data
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MonitoringListFilters from './MonitoringListFilters.vue'
import useMonitoringList from './useMonitoringList'
import monitoringStoreModule from '../monitoringStoreModule'
import MonitoringListAddNew from './MonitoringListAddNew.vue'
import { codeMessageBox, codePrevent } from './code'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    MonitoringListFilters,
    MonitoringListAddNew,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationContent,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BOverlay
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      boxOne: '',
      boxTwo: '',
      codeMessageBox,
      codePrevent,
      name: '',
      nameState: null,
      submittedNames: [],
      url: '',
      show: false
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const MONITORING_APP_STORE_MODULE_NAME = 'app-monitoring'

    // Register module
    if (!store.hasModule(MONITORING_APP_STORE_MODULE_NAME)) store.registerModule(MONITORING_APP_STORE_MODULE_NAME, monitoringStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MONITORING_APP_STORE_MODULE_NAME)) store.unregisterModule(MONITORING_APP_STORE_MODULE_NAME)
    })

    const userDataInfo = ref([])
    const tbl_detail_status = ref([])

    // const buOptions = ref([])
    const salesOptions = ref([])
    const diskondimintaOptions = ref([])
    const muOptions = ref([])
    onMounted(async () => {
      // const result = await axios.post('http://192.168.254.196:9183/api_bsc/hakAkses/listBu')
      // const dataBU = result.data.data
      // const combo = []
      // // eslint-disable-next-line no-plusplus
      // for (let i = 0, len = dataBU.length; i < len; i++) {
      //   combo.push({ label: dataBU[i].nama_bu, value: dataBU[i].id_bu })
      // }
      // buOptions.value = combo

      const resultSales = await axios.post(`${useAppConfig().AppUrl}monitoring_pengajuan_sales/listSales`)
      const dataSales = resultSales.data[0].data
      const comboSales = []
      for (let i = 0, len = dataSales.length; i < len; i++) {
        comboSales.push({ label: dataSales[i].map_user_name, value: dataSales[i].map_user_pk })
      }
      salesOptions.value = comboSales 

      const resultDDO = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/listDiskonDiminta`)
      const dataDDO = resultDDO.data[0].data
      const comboDDO = []
      for (let i = 0, len = dataDDO.length; i < len; i++) {
        comboDDO.push({ label: dataDDO[i].trn_diskon_request, value: dataDDO[i].trn_diskon_request })
      }
      diskondimintaOptions.value = comboDDO

      const resultMU = await axios.post(`${useAppConfig().AppUrl}global/listMU`, {bu_id: localStorage.getItem('bisnis_unit')})
      const dataMU = resultMU.data[0].all_mu
      const comboMU = []
      for (let i = 0, len = dataMU.length; i < len; i++) {
        comboMU.push({ label: dataMU[i].cust_code_name, value: dataMU[i].cust_code })
      }
      muOptions.value = comboMU
    })

    const isAddNewMonitoringSidebarActive = ref(false)

    const jenisOptions = [
      { label: 'Baru', value: 'baru' },
      { label: 'Sisa Kuota', value: 'sisa_kuota' },
    ]
    const dfOptions = [
      { label: 'Ya', value: '1' },
      { label: 'Tidak', value: '0' },
    ]
    const premiOptions = [
      { label: 'Ya', value: '1' },
      { label: 'Tidak', value: '0' },
    ]

    const {
      fetchMonitoring,
      tableColumns,
      perPage,
      currentPage,
      totalMonitoring,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMonitoringListTable,
      refetchData,

      // UI
      resolveMonitoringRoleVariant,
      resolveMonitoringRoleIcon,
      resolveMonitoringStatusVariant,

      // Extra Filters
      // roleFilter,
      // planFilter,
      proyekFilter,
      tglpengajuanFilter,
      salesFilter,
      jenisFilter,
      diskondimintaFilter,
      periodestartFilter,
      periodeendFilter,
      muFilter,
      dfFilter,
      premiFilter,
    } = useMonitoringList()

    return {
      session,
      // Sidebar
      isAddNewMonitoringSidebarActive,

      fetchMonitoring,
      tableColumns,
      perPage,
      currentPage,
      totalMonitoring,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMonitoringListTable,
      refetchData,
      // buOptions,

      // Filter
      avatarText,

      // UI
      resolveMonitoringRoleVariant,
      resolveMonitoringRoleIcon,
      resolveMonitoringStatusVariant,

      // roleOptions,
      // planOptions,
      userDataInfo,

      tbl_detail_status,

      // Extra Filters
      // roleFilter,
      // planFilter,
      proyekFilter,
      tglpengajuanFilter,
      salesFilter,
      salesOptions,
      jenisFilter,
      jenisOptions,
      diskondimintaFilter,
      diskondimintaOptions,
      periodestartFilter,
      periodeendFilter,
      muFilter,
      muOptions,
      dfFilter,
      dfOptions,
      premiFilter,
      premiOptions,
    }
  },
  methods: {
    //  rowClass(item, key) {
    //     if (item.cs_category == 'PP') return 'table-success' 
    //   },
    async download_header(){
      // alert('Download Header')
      // this.url = `${useAppConfig().AppUrl}monitoring_pengajuan_sales/download/123`
      // window.location.href = `${useAppConfig().AppUrl}monitoring_pengajuan_sales/download/123`
      const params = new URLSearchParams()
      params.append('id', 'header')
      params.append('emp_id', localStorage.getItem('emp_id'))
      params.append('trn_name_project', this.proyekFilter)
      params.append('trn_date_request', this.tglpengajuanFilter)
      params.append('trn_created_by', this.salesFilter)
      params.append('trn_type', this.jenisFilter)
      params.append('trn_diskon_request', this.diskondimintaFilter)
      params.append('trn_periode_start', this.periodestartFilter)
      params.append('trn_periode_end', this.periodeendFilter)
      params.append('trn_mitrausaha', this.muFilter)
      params.append('trn_program_df', this.dfFilter)
      params.append('trn_premi', this.premiFilter)

      window.open(`${useAppConfig().AppUrl}monitoring_pengajuan_sales/download?`+params, '_blank');
    },
    async download_detail(){
      const params = new URLSearchParams()
      params.append('id', 'detail')
      params.append('emp_id', localStorage.getItem('emp_id'))
      params.append('trn_name_project', this.proyekFilter)
      params.append('trn_date_request', this.tglpengajuanFilter)
      params.append('trn_created_by', this.salesFilter)
      params.append('trn_type', this.jenisFilter)
      params.append('trn_diskon_request', this.diskondimintaFilter)
      params.append('trn_periode_start', this.periodestartFilter)
      params.append('trn_periode_end', this.periodeendFilter)
      params.append('trn_mitrausaha', this.muFilter)
      params.append('trn_program_df', this.dfFilter)
      params.append('trn_premi', this.premiFilter)

      window.open(`${useAppConfig().AppUrl}monitoring_pengajuan_sales/download?`+params, '_blank');
    },
    async detail_pengajuan(trn_code){
      this.$router.push({
        name: 'apps-monitoring-pengajuan-detail', 
        params: { trn_code: trn_code }
      })
    },
    async download_pdf(trn_code){
      const params = new URLSearchParams()
      params.append('id', 'pdf')
      params.append('trn_code', trn_code)
      // params.append('emp_id', localStorage.getItem('emp_id'))
      // params.append('trn_name_project', this.proyekFilter)
      // params.append('trn_date_request', this.tglpengajuanFilter)
      // params.append('trn_created_by', this.salesFilter)
      // params.append('trn_type', this.jenisFilter)
      // params.append('trn_diskon_request', this.diskondimintaFilter)
      // params.append('trn_periode_start', this.periodestartFilter)
      // params.append('trn_periode_end', this.periodeendFilter)
      // params.append('trn_mitrausaha', this.muFilter)
      // params.append('trn_program_df', this.dfFilter)
      // params.append('trn_premi', this.premiFilter)

      window.open(`${useAppConfig().AppUrl}monitoring_pengajuan_sales/download_pdf?`+params, '_blank');
    },
    async status_detail(sts, flag){
      // alert('status e opo? '+sts);
      if(sts == true){
        const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/detail_status`, {trn_code: flag})
        this.tbl_detail_status = result.data[0]

        console.log(this.tbl_detail_status);  
        this.$refs['status-modal'].show()
      }
      else{
        this.tbl_detail_status = null
        this.$refs['status-modal'].hide()
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      console.log(this.name)
      const params = new URLSearchParams()
      params.append('param', 'duplicate_category')
      params.append('cs_bu', this.name.value)
      params.append('cs_bu_resources', localStorage.getItem('bisnis_unit'))
      params.append('created_by', localStorage.getItem('emp_id'))
      const result = await axios.post('http://192.168.254.196:9183/api_bsc/cat_score/duplicate', params)
      console.log(result)
      // this.refetchData()

      if (result.data.message === 'Berhasil duplicate data') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: result.data.message,
            variant: 'success',
          },
        })
        this.refetchData()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: result.data.message,
            variant: 'danger',
          },
        })
        this.refetchData()
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

    async showMsgBoxTwo(id) {
      this.boxTwo = id
      console.log(id)
      this.$bvModal
        .msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
          title: 'Konfirmasi',
          size: 'sm',

          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'post_keterangan_id')
            params.append('cs_id', id)
            const result = await axios.post('http://192.168.254.196:9183/api_bsc/cat_score/delete', params)
            if (result.data.message === 'Berhasil menghapus data') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'BellIcon',
                  text: 'Gagal menghapus data!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
